.job-roles .job-roles-header {
  text-align: center;
  margin: 2rem 0;
}
.job-roles .jobroles-inner-sec {
  margin: 5rem 0;
}
.job-roles .jobroles-inner-sec {
  border-bottom: 2px solid #91d11b;
  padding-bottom: 20px;
}
.job-roles .section-disc {
  max-width: 1000px;
  margin: auto;
  text-align: start;
}

.job-roles .jobroles-inner-sec .servies-list {
  margin-top: 2rem;
}
.job-roles .jobroles-inner-sec .servies-list ul {
  padding: 0;
}
.job-roles .jobroles-inner-sec p {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 180%;
  text-align: justify;
  letter-spacing: 0.02em;
  color: #000000;
  margin-top: 10px;
  line-height: normal;
}
.job-roles .jobroles-inner-sec .sub-heading {
  font-weight: 600;
  font-size: 40px;
  line-height: 97.5%;
  letter-spacing: -0.02em;
  color: #000000;
}
