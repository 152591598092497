.team-btn {
  min-width: 242px;
  text-align: center;
  background: #91d11b;
  padding: 10px 30px;
  border-radius: 40px;
  font-weight: 700;
  font-size: 18px;
  color: #000 !important;
  display: inline-block;
  cursor: pointer;
  border: none;
  text-decoration: none;
}

.team-btn:hover {
  color: #fff !important;
  background: #91d11b !important;
  text-decoration: none;
}

.disable {
  opacity: 0.6;
  pointer-events: none;
}

.cursor-pointer{
  cursor: pointer;
}


/* font classes */
.fw-bold {
  font-weight: bold;
}