.home-banner.careers-banner h1 {
  margin-bottom: 1rem;
  font-size: 40px !important;
}
.home-banner.careers-banner p {
  font-weight: 400;
  font-size: 22px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #000000;
  margin-bottom: 1rem;
}
.home-banner.careers-banner ul li {
  margin-bottom: 20px;
}
.the-solution.how-work2 {
  background-color: white;
}
.the-solution.how-work2 .uper-card {
  text-align: center;
  max-width: 300px;
}
.the-solution.how-work2 .uper-card p {
  font-size: 20px;
  margin-top: 20px;
}
.the-solution.how-work2 .card h2 {
  margin: 1rem 0;
}
.the-solution.how-work2 .card .card-body {
  max-width: 900px;
}
.the-solution.how-work2 .card .card-body h6 {
  font-size: 18px;
  padding-top: 10px;
  padding-left: 12px;
}
.growing-together {
  padding: 3rem 0;
  background-color: #f1f5ef;
}
.growing-together .sub-heading {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 120%;
  color: #000000;
}
.growing-together .form-control {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border: none;
}
input[type="file"]::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #91d11b;
  border-radius: 5px;
  padding: 2px 20px;
  color: #000;
  cursor: pointer;
  float: right;
}

.careers-btn .team-btn {
  border-radius: 12px;
  padding: 8px 30px;
  font-size: 16px;
  font-weight: 600;
}
@media (max-width: 768px) {
  .home-banner.careers-banner h1 {
    
    margin-top: -30px !important;
}
}
