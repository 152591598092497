.team-popup-cotent {
  padding: 0 15px 50px;
  background: #fff;
  border-radius: 5px;
}

.schedule-call img{width:100%;max-width:450px;}
.schedule-call h3{
z-index:1;
position:relative;
}
.bill-heading {
  padding: 30px 0 20px;
}

.started-thankyou-container{
  background-color: #F1F5EF;
}
.started-thankyou-container .team-popup-cotent{
  background-color: #F1F5EF;
}
.ladyimg{    margin-top: 15px;
    padding-top: 30px;
    background: #F1F5EF;
    height: 447px;}
.green-color{
    height: 81px;
    background: #91D11B;
    margin: 0px -15px;
    border-radius: 0px 0px 10px 10px;
}
.gray-color{
    height: 185px;
    background: #F1F5EF;
    margin-top: -185px;
}
.thnk-img{display: block;margin: auto;}