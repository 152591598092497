.home-banner.careers-banner .answerringH1{margin-top: -25px;}
.home-banner.careers-banner h1 {
      margin-bottom: 1rem;
    font-size: 70px;
    line-height: 110%;
    margin-top: -100px;
}

.lead{font-size:64px !important;}
.order{font-size:60px !important;}
.home-banner.careers-banner p {
  font-weight: 400;
  font-size: 19px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #000000;
  margin-bottom: 1rem;
}
.dsgn-img1{
float:right;
margin-top:-20px;
}
.dsgn-img3{
float:right;
margin-top:-20px;
position:absolute;
right:0;
}
.dsgn-img2{
margin-top:-160px;
}
 .service-form .banner-from {
  margin-bottom: 40px;
  margin-top:-100px;
}
.service-form .card{border:2px solid rgba(0, 0, 0, 0.125) !important;}
 .team-btn {
  border-radius: 5px;
  padding: 8px 20px;
  color: white !important;
  font-weight: 500;
  margin-top: 5px;
  font-size: 15px;
  border: none;
}

 .service-form .banner-from .card-header {
  background: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin: 0;
}

 .service-form .banner-from .card-header h5 {
      font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 130.5%;
    text-align: center;
    color: #000;
}

 .service-form .banner-from input,
 .service-form .banner-from select {
  background: #ffffff;
  border: 1px solid rgba(78, 86, 88, 0.5);
  border-radius: 5px;
  color: rgba(78, 86, 88, 0.8);
  min-height: 38px;
  font-size: 14px;
}

 .banner-from .col-lg-6,
 .banner-from .col-lg-12 {
  padding: 0 5px;
}
.service-form .pd30, .service-benefits .pd30{padding:0px 30px;}
.service-form .card-header{border-bottom:0px;}
.service-form .card-body{padding:0px 2rem 2rem;}
.right-inf{margin-top:8%;}
.right-inf h2{    font-weight: 600;
    margin-bottom: 30px;}
	.right-inf p {font-size:17px;padding-bottom:10px;}
.right-inf p b{color:#91D11B;}
.service-benefits{padding-bottom:60px;}
.service-benefits h2{font-weight:600;padding-top:47px;}
.service-benefits h3{
    font-size: 18px;
    font-weight: 600;
    margin-left: 70px;
	margin-bottom:25px;
	}
	.service-benefits h3 img{    padding-right: 20px;}
  @media (max-width: 768px) {
    .right-inf h2 {
      font-size: 22px;
  }
  .service-form .dsgn-img1, .dsgn-img3{display: none;}
  .service-form .banner-from .card-header h5{font-size: 20px;}
  .dsgn-img2 {
    margin-top: -30px;
}
.service-benefits h3 {
  font-size: 15px;
  font-weight: 500;
  margin-left: 0px;
  margin-bottom: 15px;
}
.service-benefits {
  padding-bottom: 0px;
}
.client-word .heading{font-size: 32px;}
  }
