@media (min-width: 1600px) {
  .container {
    max-width: 1329px;
  }
}
@media (max-width: 1349px) {
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
