.home-banner.buildteam-home h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 120%;
  color: #000000;
  text-align: start;
}
.build-team{
padding: 3rem 0;
}
.build-team .sub-heading{
  font-size: 40px;
  font-weight: 700;
}
.landing-bnr .home-banner h1 {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 40px !important;
  line-height: 44px !important;
  top: -180px;
  position: relative;
  padding: 30px 20px 36px;
  color: #000000;
  width: auto !important;
}
.landing-bnr .home-banner h2 {
  text-align: center;
  margin-top: -15px;
  font-size: 25px;
}
.landing-bnr .home-banner .banner-from {
  margin-bottom: -50px !important;
  margin-top: 45px;
  box-shadow: 0px 0px 15px 0px #00000040;
}

.landing-about .grn-circle{
  width: 100%;
  padding: 150px 20px;
}
.landing-about .grn-circle .text h2{
  font-family: Poppins;
  font-size: 40px;
  font-weight: 700;
  line-height: 46px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
  margin-top: -144px;
}
.landing-about .grn-circle .text p{
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  width: 100px;
  margin: auto;
  color: #fff;
}
.landing-about .grn-circle .text{
  width: 33%;
  float: left;
  margin-top: -115px;
  text-align: center;
}
.landing-about .col-lg-5 img{
  width: -webkit-fill-available;
}
.landing-about .col-lg-7 p{
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: -0.02em;
  text-align: justify;
  padding: 45px 30px;
}
.landing-about{padding-bottom: 60px;}
.landing-why{
  background: #F1F5EF;
  padding: 50px 0px;
}
.landing-why h3{
  font-family: Poppins;
  font-size: 35px;
  font-weight: 600;
  line-height: 53px;
  letter-spacing: -0.02em;
  text-align: center;
  padding: 0px 20%;
  margin-bottom: 20px;
  width:100%;
}
.landing-why .txt{
  padding-left: 50px;
  margin-top: -30px;
}
.landing-why .txt h6{
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;
}
.landing-bnr .home-banner .ladyImg{left:35px;}
.landing-why .txt p{
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;
  padding-right: 40px;
}
.landing-why .col-lg-4{
  margin-bottom: 30px;
}
.landing-why .btn, .landing-started .btn{
  border: 2px solid #91D11B;
  padding: 12px;
  border-radius: 22px;
  display: inline-grid;
}
.landing-why .btn a, .landing-started .btn a{
  background: #91d11b;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #fff;
  border-radius: 15px;
  padding: 25px 35px;
}
.bgwhite{background: #fff;}
.bgwhite h3{padding: 0px;margin: 0px auto 20px;}
.landing-advantage{
  background: #F1F5EF;
  padding: 50px 0px;
}
.landing-advantage h3{
  font-family: Poppins;
  font-size: 35px;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: -0.02em;
  text-align: left;
  padding: 60px 0px 20px;
}
.landing-advantage p{
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.02em;
  text-align: justify;
}
.landing-started{padding: 50px 0px;}
.landing-started .btn{
  margin: auto;
  width: 300px;
  display: grid;
}
.landing-started .ldy{
  width: -webkit-fill-available;
  max-width: 328px;
  margin: auto;
  display: block;
  margin-bottom: 30px;
}
.landing-started h3{
  font-family: Poppins;
  font-size: 35px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  padding: 30px 0px 50px;
}
.landing-started h3 span{    color: #91D11B;}
.landing-started p{
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin: -27px 50px 50px;
}
.bgGray{    background: #F1F5EF;}
@media (max-width: 998px) {
  .home-banner.buildteam-home h1 {
    font-size: 26px;
    line-height: normal;
  }
  .build-team .sub-heading{
    font-size: 25px;
    font-weight: 700;
  }
  .grn-circle .grn-line{    width: -webkit-fill-available;}
  .landing-bnr .home-banner h1{
    font-size: 36px !important;
    line-height: 40px !important;
    top: -20px;
  }
  .landing-about {
    padding-bottom: 0px;
}
.landing-why h3 {
  font-size: 32px;
  padding: 0px 5%;
  line-height: 40px;
}
.landing-advantage h3{
  font-size: 32px;
  line-height: 40px;
}
}
@media (max-width: 768px) {
  .landing-bnr .home-banner h1 {
    font-size: 32px !important;
    line-height: 40px !important;
    top: -20px;
    padding: 30px 0px 10px;
}
}
@media (max-width: 480px) {
  .landing-about .grn-circle .text img{
    width: -webkit-fill-available;
    float: left;

  }
  .landing-about .grn-circle .text{margin-top: -67px;}
  .landing-about .grn-circle {
    width: 100%;
    padding: 80px 20px 50px;
}
  .landing-about .grn-circle .text h2 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 700;
    line-height: 46px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
    margin-top: 15px;
    z-index: 9;
    padding-left: 30px;
    position: absolute;
}
.landing-about .grn-circle .text p {
  font-family: Poppins;
  font-size: 11px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  width: 93px;
  margin: auto;
  color: #fff;
  margin-top: 50px;
  z-index: 9;
  padding-left: 16px;
  position: absolute;
}
.landing-about .col-lg-7 p{font-size: 16px;line-height: 24px;}
.landing-why h3 {
  font-size: 24px;
  padding: 0px 5%;
  line-height: 40px;
}
.landing-why .btn, .landing-started .btn{margin: 0px auto;}
.landing-advantage h3 {
  font-size: 28px;
  line-height: 36px;
}
.landing-started h3 {
  font-family: Poppins;
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  padding: 30px 0px 30px;
}
.landing-started p {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin: -27px 45px 30px;
}
.landing-advantage img{
  width: -webkit-fill-available;
}
}
