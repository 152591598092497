.home-banners .home-banner {
  background-color: #f1f5ef;
  padding: 3rem 0 0 0;
}

.home-banners .home-banner h1 {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 60px !important;
  line-height: 69px !important;
  color: #000000;
  width: auto !important;
}
.home-banners .home-banner h2{
  text-align: center;
  margin-top: -15px;
}
.home-banners .home-banner .c1{
  width: 35px;
  display: block;
  background: #91D11B;
  height: 35px;
  border-radius: 50%;
}
.home-banners .home-banner .l1{
  position: absolute;
  display: block;
  width: 112px;
  height: 112px;
  border: 2px solid #91D11B;
  border-radius: 50%;
  right: 50px;
}
.home-banners .home-banner .ladyImg{
  width: auto !important;
  float: left;
  position: absolute;
  left: 15px;
  bottom: -135px;
}
.home-banners .home-banner .right-info {
  text-align: center;
  margin-bottom: 40px;
  top:-75px
}
.home-banners .home-banner .right-info img {
  width: 100%;
  margin: 10px 0;
}
.home-banners .home-banner .banner-from {
  margin-bottom: -10px !important;
  margin-top: 45px;
  box-shadow: 0px 0px 15px 0px #00000040;
  border-radius: 15px;
}
.home-banners .home-banner .banner-from .card{border-radius: 15px;}
.home-banners .home-banner .banner-from .card h2{    padding: 50px 0px 20px;
  font-weight: 600;}
  .home-rating-banner{margin-top: 100px !important;}
  .banner-from .formField{    padding: 0px 50px;}
  .banner-from .formField p{
    font-size: 15px;
    line-height: 18px;
    font-weight: 400;
    font-family: 'Poppins';
    margin-bottom: 10px;
  }
  .banner-from .formField span{
    color: red;
    font-size: 12px;
    position: relative;
  }
  .banner-from .formField .textField{
    border-radius: 10px;
    height: 58px;
    width: -webkit-fill-available;
    font-size: 15px;
    font-weight: 400;
    padding-left: 25px;
    position: relative;
  }
  .banner-from .bGray{
    background: #F1F5EF;
    height: 138px;
    border-radius: 70px 0px 15px 15px;
    margin-top: -25px;
  }
.banner-from .team-btns {
  border-radius: 15px;
  padding: 8px 20px;
  color: #000 !important;
  font-weight: 600;
  font-size: 25px;
  border: none;
  margin: auto;
  display: block;
  margin-top: 50px;
  min-width: 242px;
    text-align: center;
    background: #91d11b;
    cursor: pointer;
}
.home-banners .home-banner .banner-from .card-header {
  background: #91d11b;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin: 0;
}
.home-banners .home-banner .banner-from .card-header h5 {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 104.5%;
  text-align: center;
  color: #fff;
}
.home-banners .home-banner .banner-from input,
.home-banners .home-banner .banner-from select {
  background: #ffffff;
  border: 1px solid rgba(78, 86, 88, 0.5);
  border-radius: 5px;
  color: rgba(78, 86, 88, 0.8);
  min-height: 38px;
  font-size: 14px;
}
.home-banners .home-banner .banner-from .col-lg-6,
.home-banners .home-banner .banner-from .col-lg-12 {
  padding: 0 5px;
}
/* .banner-from .card{display: none;} */
.home-banners .home-banner .col-lg-5{
padding-right: 50px;
}
.banner-from .form2{
  border-radius: 15px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 15px;
  padding: 15px 35px 0px;
}
.banner-from .form2 p{
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 5px;
  padding-top: 10px;
}
.banner-from .form2 .textField{
  padding:0px 10px;
  width: inherit;
}
.banner-from .form2 .msgField{
  width: -webkit-fill-available;
  min-height: 80px;
  padding: 5px 10px;
}
.banner-from .form2 .bGray {
  background: #F1F5EF;
  height: 79px;
  border-radius: 70px 0px 15px 15px;
  margin: 15px -35px 0px;
}
.banner-from .form2 .team-btns{margin-top: -10px;}
.green-banner{
  background: #000;
  height: 105px;
  border-radius: 0px 0px 28px 28px;
  padding: 45px 45px 45px 220px;
}
.banner-from .form3 h6{
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  padding-top: 10px;
}
.banner-from .form3 img{
  margin: auto;
  display: block;
}
.banner-from .form3 p{
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  padding: 0px 45px;
}
.banner-from .form3 .bGreen {
  background: #91d11b;
  height: 79px;
  border-radius: 70px 0px 15px 15px;
  margin: 15px -35px 0px;
}
.green-banner .hNumber{
  text-align: center;
  width: fit-content;
  padding: 0px 35px;
  float:left;
}
.green-banner .hNumber h4{
  font-size: 30px;
  color: #fff;
  font-weight: 700;
  line-height: 34.5px;
  margin-bottom: 0px;
}
.green-banner .hNumber h7{
  font-size: 13px;
  color: #91D11B;
  font-weight: 600;
  line-height: 15px;
  margin-top: -25px;
  padding-bottom: 10px;
  display: block;
}
.green-banner .hNumber p{font-size: 18px;}
.welcome-stf h2{
  font-size: 65px;
  line-height: 75px;
  font-weight: 500;
  padding: 155px 30px 0px;
}
.welcome-stf h2 span, .welcome-stf h4 span, .welcome-stf h1 span{ 
  color: #91D11B;
}
.welcome-stf p{
  font-family: Poppins;
  font-size: 17px;
  font-weight: 300;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0px 187px 100px 30px;
}
.welcome-stf .rghtImg{
  width: -webkit-fill-available;
  margin-top: 50px;
}
.welcome-stf h4{
      font-size: 35px;
    line-height: 40px;
    font-weight: 500;
    padding-top: 105px;
}
.welcome-stf h1{
  font-size: 40px;
line-height: 50px;
font-weight: 600;
padding-top: 105px;
}
.welcome-stf .line{
  height: 2px;
  width: 100%;
  margin-top: -31px;
}
.welcome-stf .line1{
  height: 360px;
  margin: 30px 30px 20px 50px;
  float: left;
}
.welcome-stf .Services .subDiv{padding-top: 40px;}
.welcome-stf .Services h5{
  font-family: Poppins;
font-size: 25px;
font-weight: 500;
line-height: 29px;
letter-spacing: 0em;
text-align: left;

}
.welcome-stf .Services .subDiv p{
  font-family: Poppins;
  font-size: 16px;
  font-weight: 300;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0px 0px 10px;
}
.apart{background: #F1F5EF;margin: 50px 0px;}
.apart .grp{padding-left: 30px;}
.apart h3{
  font-family: Poppins;
  font-size: 50px;
  font-weight: 500;
  line-height: 57px;
  letter-spacing: 0em;
  text-align: left;
  padding: 50px 50px 30px;
  width: 100%;
}
.apart .fcImg{
  position: relative;
  margin-top: -116px;
  margin-left: 27px;
  z-index: 99;
  display: block;
}
.apart .grp h4{
  font-family: Poppins;
  font-size: 25px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}
.apart .grp .pd1{padding-top: 60px;}
.apart .grp .pd2{padding-top: 53px;}
.apart .grp .pd3{padding-top: 68px;}
.apart .grp p{
  font-family: Poppins;
  font-size: 16px;
  font-weight: 300;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  padding-right: 60px;
  padding-bottom: 20px;
}
.g-section{
  background: #91D11B;
  height: 111px;
  padding: 29px 15%;
}
.g-section .hNumber{
  text-align: center;
  width: fit-content;
  padding: 0px 10%;
  float:left;
}
.g-section .hNumber h4{
  font-size: 30px;
  color: #fff;
  font-weight: 700;
  line-height: 34.5px;
  margin-bottom: 0px;
}
.our-service{display: flow-root;}
.our-service h4{
  font-family: Poppins;
  font-size: 35px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 30px;
  padding-bottom: 50px;
  width: 100%;
}
.our-service img{
  float: left;
  padding-right: 10px;
}
.our-service h5{
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-align: left;
  padding-top: 25px;
  padding-bottom: 30px;
}
.our-service .service-img{    float: right;
  margin-top: -100px;
  padding-right: 0px;
  width: 367px;}
.why-partner{background: #F1F5EF;
}
.why-partner h2{
font-family: Poppins;
font-size: 48px;
font-weight: 500;
line-height: 57px;
letter-spacing: 0em;
text-align: left;
padding-left: 30px; padding-top: 50px;}
.why-partner h2 span{color: #91D11B;font-weight: 700;}
.why-partner .partner-text{    padding: 30px 15px 0px;}
.why-partner .partner-text span{
  border: 5px solid #91D11B;
  display: block;
  height: 46px;
  float: left;
  margin-top: 6px;
}
.why-partner .partner-text h5{
  padding-left: 20px;
  font-family: Poppins;
  font-size: 25px;
  font-weight: 500;
  display: table-cell;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  height: 58px;
  vertical-align: middle;
}
.why-partner h4{
  font-family: Poppins;
  font-size: 30px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: center;
  width: 100%;
  padding: 20px;
}
.why-partner a{
  margin: auto;
  width: 336px;
  height: 67px;
  background: #91D11B;
  border-radius: 15px;
  font-family: Poppins;
  font-size: 30px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
  padding-top: 15px;
  margin-bottom: -20px;
}
.why-partner img{
  float: right;
  margin-top: -36%;
  width: 36%;
}
.how-works{    padding: 80px 30px 50px;}
.how-works h2{
  font-family: Poppins;
  font-size: 32px;
  font-weight: 500;
  line-height: 57px;
  letter-spacing: 0em;
  text-align: left;
}
.how-works h2 span{
  color:#91D11B;
}
.how-works p{
  font-family: Poppins;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 20px;
}
.how-works .right-img{
  width: -webkit-fill-available;
  margin-top: 20px;
}
.pdt0{padding-top: 0px !important;}
.why-partner .partner-text p{
  font-family: Poppins;
font-size: 16px;
font-weight: 300;
line-height: 27px;
letter-spacing: 0em;
text-align: left;

}
.how-works .how-works-text .green-line{
  border: 5px solid #91D11B;
  display: block;
  height: 46px;
  float: left;
  margin-top: 6px;
}
.how-works .how-works-text h5{
  padding-left: 20px;
  font-family: Poppins;
  font-size: 25px;
  font-weight: 500;
  display: table-cell;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  height: 58px;
  vertical-align: middle;
}
.how-works .how-works-text .line1{
  margin: -53px 20px 20px -8px;
  float: left;
}
.how-works .how-works-text p{
  padding-top: 0px;
  margin: 15px 0px 30px;
}
.how-works .how-works-text .pFirst{
  margin-top: 24px;
}
.how-works .how-works-text{margin-top: 30px;}
.top-bpo{
  background: #F1F5EF;
  padding: 60px 0px 0px 30px;
}
.top-bpo h3{
  font-family: Poppins;
  font-size: 35px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0em;
  margin: auto;
}
.top-bpo h5{
  font-family: Poppins;
  font-size: 25px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  margin: auto;
  padding: 5px 0px 50px;
}
.top-bpo h4{
  font-family: Poppins;
  font-size: 30px;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 56px;
  padding-top: 20px;
}
.top-bpo .right-div span{
  display: flex;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  padding-left: 20px;
  height: 50px;
}
.top-bpo .right-div{
  margin-bottom: 30px;
  display: block;
}
.top-bpo .right-div img{
  float:left;
}
.top-bpo .btn-border{
  border: 2px solid #91D11B;
  border-radius: 20px;
  padding: 6px;
  max-width: 450px;
  display: grid;
  margin-top: 20px;
}
.top-bpo .btn-border a{
  font-family: Poppins;
  font-size: 23px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
  background: #91D11B;
  padding: 25px;
  border-radius: 15px;
}
.top-bpo .btm-line{
  float: right;
  margin-top: -164px;
}
.owl-item.active:has(+ .owl-item.active.center){
  margin-top: -100px;
  z-index: 99;
  position: absolute;
}
.owl-item.active.center{margin-right: 30px;}
.client-word h2{
  font-family: Poppins;
  font-size: 50px;
  font-weight: 500;
  line-height: 57px;
  letter-spacing: 0em;
  text-align: left;
  float: left;
  margin-bottom: 30px;
  display: block;
}
#customers-testimonials{clear: both;margin: 0px 50px;width: 90%;}
.client-word h2 span{color: #91D11B;}
.client-word .service-img{
  margin-top: -500px;
  width: 175px;
}
.new-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 55px;
  line-height: 82px;
  color: #000000;
}
.new-sub-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  color: #000000;
  margin-bottom: 1rem;
}

.who-are {
  margin: 2rem 0;
  text-align: center;
  padding: 3rem 0;
}
.who-are .business-card {
  margin-top: 20px;
  position: relative;
}

.who-are .business-card:after {
  content: "";
  position: absolute;
  background-color: #91d11b;
  width: 32px;
  height: 81px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  left: -20px;
  top: 25px;
}
.who-are .business-card .info {
  position: absolute;
  top: 50%;
  left: 46%;
  transform: translate(-50%, -50%);
}
.who-are .business-card .info h5 {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 90px;
  color: #000000;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.who-are .business-card .info h5 img {
  width: 90px;
}
.who-are p {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  color: #000000;
  margin-bottom: 1rem;
  text-align: justify;
  margin-top: 50px;
}
.who-are .business-card .main-img {
  width: 100%;
}
.heading {
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  color: #000000;
  width: fit-content;
  margin: 20px auto;
  padding-bottom: 5px;
  max-width: 850px;
  line-height: 1.2;
}
.heading-border {
  border-bottom: 4px solid #91d11b;
}
.our-services {
  background-color: #f1f5ef;
  padding: 3rem 0;
}
.scale-business {
  background-color: #f1f5ef;
  padding: 3rem 0 0 0;
  text-align: center;
}
.section-disc {
  font-style: normal;
  font-weight: 300;
  font-size: 21px !important;
  line-height: 150%;
  text-align: center;
  margin-bottom: 40px;
  color: #000000;
}

.our-services ul {
  list-style: none;
  padding: 0;
}
.servies-list h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 45px;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 30px;
}
.servies-list h5 img {
  margin-right: 8px;
  height: 40px;
}
.servies-list li {
  margin-bottom: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 118.5%;
  letter-spacing: -0.02em;
  color: #000000;
  display: flex;
  align-items: flex-start;
}
.servies-list li span {
  line-height: 0;
  background-color: #91d11b;
  border-radius: 10px;
  padding: 1px 8px;
  margin-right: 6px;
  color: white;
  margin-top: 5px;
  font-size: 12px;
}
.our-services .servies-list.border-list {
  border-right: 2px solid #91d11b;
}
.advantages {
  padding: 3rem 0;
}

.global-employer {
  background-color: #f1f5ef;
  padding: 3rem 0;
}
.global-employer .info-box {
  text-align: center;
  margin-top: 30px;
}
.global-employer .info-box h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 150%;
  text-align: center;
  color: #000000;
  margin-top: 10px;
}
.strategic-partners {
  padding: 3rem 0;
}
.strategic-partners img {
  margin-top: 4rem;
}

.how-can-help {
  background-color: #f1f5ef;
  padding: 3rem 0;
}
.how-can-help h5 {
  display: flex;
  align-items: start;
  font-size: 25px;
  font-weight: 600;
}
.how-can-help h5 img {
  width: 29px;
  margin-right: 7px;
}
.how-can-help p {
  font-size: 16x;
}
.how-can-help .col-container {
  margin-top: 1rem;
}
.built-point {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 115%;
  color: #000000;
  position: relative;
  padding-left: 20px;
  text-align: left;
  margin-top: 10px;
  font-weight: 600;
  margin-bottom: 1rem;
}
.built-point::after {
  position: absolute;
  content: "";
  left: 0;
  width: 12px;
  height: 12px;
  background-color: #91d11b;
  border-radius: 100%;
  top: 4px;
}

.how-work {
  background-color: #f1f5ef;
  padding: 3rem 0;
}
.how-work .step-work-box {
  position: relative;
}

.how-work .col-lg-3 {
  padding: 0;
}
.how-work .step-work-box .main-img {
  width: 100%;
}
.how-work .step-work-box-sm {
  margin-top: 48px;
}
.how-work .work-info {
  border: 20px solid #91d11b;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 213px;
  width: 213px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  border-radius: 100%;
  top: -150px;
  background-color: #f1f5ef;
  text-align: center;
  padding-top: 20px;
}
.how-work .work-info img {
  max-width: 80px;
  margin: 10px 0;
}
.how-work .work-info h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #000000;
}
.how-work .step-work-box p {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 150%;
  text-align: justify;
  letter-spacing: 0.02em;
  margin-right: 30px;
  color: #000000;
  margin-top: 10px;
}
.how-work-2 {
  padding: 3rem 0;
}
.work-card {
  border-radius: 10px;
  background: #f1f5ef;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: none;
}
.work-card h5 {
  font-weight: 700;
  font-size: 26px;
  margin: 10px 0;
}
.work-card h6 {
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
}
.working-steps {
  margin-top: 180px;
}
.effectivenes-employees {
  padding: 3rem 0;
}
.effectivenes-employees .contant-box {
  position: relative;
}
.effectivenes-employees .main-img {
  height: 350px;
  width: 100%;
}
.effectivenes-employees .text-box {
  background: #91d11b;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
  width: 100%;
  padding: 10px;
  top: 100px;
  left: -42px;
  padding: 20px;
}

.hire-talent {
  background-color: #f1f5ef;
  padding: 3rem 0;
}
.hire-talent .banner-from h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 130%;
  text-align: center;
  color: #000000;
  margin-bottom: 20px;
}
.client-word {
  padding: 3rem 0;
}

.client-word .card {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.client-word .card img {
  max-width: 250px;
  margin: auto;
  margin-bottom: 1rem;
}

.client-word .card h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 120.5%;
  text-align: center;
  color: #000000;
}
.client-word .card p {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 120.5%;
  text-align: center;
  color: #000000;
}
#customers-testimonials .item {
  text-align: center;
  padding: 0px;
  margin-bottom: 40px;
  -webkit-transform: scale3d(0.8, 0.8, 1);
  transform: scale3d(0.8, 0.8, 1);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#customers-testimonials .owl-item.active.center .item {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

#customers-testimonials.owl-carousel .owl-dots .owl-dot.active span,
#customers-testimonials.owl-carousel .owl-dots .owl-dot:hover span {
  background: #d9d9d9;
  transform: translate3d(0px, -50%, 0px) scale(0.9);
}
#customers-testimonials.owl-carousel .owl-dots {
  display: inline-block;
  width: 100%;
  text-align: center;
}
#customers-testimonials.owl-carousel .owl-dots .owl-dot {
  display: inline-block;
}
#customers-testimonials.owl-carousel .owl-dots .owl-dot span {
  background: #d9d9d9;
  display: inline-block;
  height: 15px;
  margin: 0 2px 5px;
  transform: translate3d(0px, -50%, 0px) scale(0.6);
  transform-origin: 50% 50% 0;
  transition: all 250ms ease-out 0s;
  width: 15px;
  border-radius: 100%;
}
.main-catagiory h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 115%;
  color: #000000;
  position: relative;
  padding-left: 20px;
  text-align: left;
  margin-top: 10px;
}
.main-catagiory h5::after {
  position: absolute;
  content: "";
  left: 0;
  width: 12px;
  height: 12px;
  background-color: #91d11b;
  border-radius: 100%;
  top: 4px;
}
.home-rating-banner img {
  width: 100px !important;
  margin: 0 !important;
}
.home-rating-banner h6 {
  text-align: right !important;
  font-size: 13px;
  padding-right: 60px;
  font-weight: 600;
}
.home-rating-banner h6 span {
  margin-top: 5px;
}

@media (max-width: 767px) {
  .who-are .business-card:after {
    display: none;
  }
}
@media (max-width: 767px) {
  .who-are .business-card .main-img {
    width: 100%;
  }
  .who-are .business-card:after {
    display: none;
  }
  .our-services .servies-list.border-list {
    border: none;
  }
  .strategic-partners img {
    margin-top: 2rem;
  }
}
@media (max-width: 998px) {
  .how-work .step-work-box {
    margin-top: 210px;
  }
  .working-steps {
    margin-top: 0px;
  }
  .how-work .step-work-box p {
    margin-right: 0;
  }
  .how-work .col-lg-3 {
    padding: 0 15px;
  }
  .effectivenes-employees .text-box {
    position: unset;
    margin-top: 10px;
    border-radius: 10px;
  }
  .hire-talent .banner-from {
    margin-top: 30px;
  }
  .hire-talent {
    border-top-left-radius: 50px;
  }
  .who-are .business-card .info h5 {
    font-size: 25px;
  }
  .advantages .col-lg-3 {
    text-align: center;
  }
  .advantages .col-lg-3 img {
    margin-top: 1rem;
  }
  .servies-list ul {
    padding-left: 10px;
  }
}
@media (min-width: 998px) {
.home-banners .home-banner .right-info {
    text-align: center;
    margin-bottom: -55px !important;
}
.home-banners .home-banner h1 {width:130%;}
.home-banners .home-banner h3 {
  width: auto;
  font-weight: 400;
  font-size: 25px;
  line-height: 28px;
}
}
@media (max-width: 768px) {
  .home-banners .home-banner .right-info {
    text-align: center;
    margin-bottom: -55px !important;
    top:-20px;
}
.home-banners .home-banner h3 {
  width: auto;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}
.home-banners .home-banner h1 {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 30px !important;
  line-height: 34px !important;
  color: #000000;
  width: auto !important;
}
.home-banners .home-banner h2{font-size: 26px;}
.home-banners .home-banner{padding: 3rem 10px;}
.home-banners .home-banner .ladyImg{display: none;}
.home-rating-banner {
  margin-top: 0px !important;
  margin-bottom: 50px;
}
.home-banners .home-banner .col-lg-5{padding: 0px 15px;}
.green-banner {
  padding: 45px 0px 45px 0px;
}
.green-banner .hNumber {
  padding: 0px 16px;
}
.welcome-stf h2 {
  font-size: 44px;
  line-height: 50px;
  text-align: center;
  font-weight: 500;
  padding: 40px 30px 0px;
}
.welcome-stf p{padding: 0px;}
.welcome-stf .rghtImg{margin-top: 0px;}
.welcome-stf h4 {
  text-align: center;
  padding-top: 20px;
}
.welcome-stf .line1{margin: 20px;}
.welcome-stf .Services .subDiv{padding-top: 20px;}
.apart h3 {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
  padding: 30px 20px 30px;
  width: 100%;
}
.g-section {
  padding: 29px 0%;
  display: none;
}
.g-section .hNumber {
  padding: 0px 2%;
}
.g-section .hNumber h4 {
  font-size: 24px;
  color: #fff;
  font-weight: 600;
  line-height: 28.5px;
  margin-bottom: 0px;
}
.our-service h4{padding-bottom: 0px;}
.our-service h5 {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.02em;
  text-align: left;
  padding-top: 23px;
  padding-bottom: 7px;
}
.our-service .service-img{width: 200px;}
.why-partner h2 {
  font-family: Poppins;
  font-size: 36px;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: center;
  padding-left: 0px;
  padding-top: 30px;
}
.why-partner .partner-text {
  padding: 10px 15px 0px;
}
.why-partner .partner-text h5{font-size: 22px;}
.why-partner img{display: none;}
.how-works {
  padding: 50px 20px 50px;
}
.how-works h2 {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
}
.how-works .how-works-text p {
  padding-top: 0px;
  margin: 10px 0px 20px;
  font-size: 15px;
}
.top-bpo {
  background: #F1F5EF;
  padding: 30px 20px;
}
.top-bpo h3 {
  text-align: center;
  font-family: Poppins;
  font-size: 27px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  margin: auto;
}
.top-bpo h5 {
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  line-height: 26px;
  padding: 10px 0px 20px;
}
.top-bpo .ine1{margin-bottom: 40px;width: inherit;}
.top-bpo h4 {
  font-size: 24px;
  padding-left: 0px;
}
.top-bpo .btm-line{display: none;}
.top-bpo .btn-border a {
  font-size: 20px;
  padding: 10px;
}
.client-word h2 {
  font-size: 40px;
  font-weight: 500;
  line-height: 50px;}
  #customers-testimonials {
    clear: both;
    margin: 0px 0px;
    width: 100%;
}
.owl-carousel .owl-stage-outer{height: 400px;}
.client-word .service-img {
  margin-top: -280px;
  width: 175px;
}
.ftimg {
  margin-top: -5px !important;
}
.welcome-stf .Services h5{font-size: 22px;}
}
@media (min-width: 980px) and (max-width: 1200px) {
  .home-banners .home-banner h1 {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 50px !important;
    line-height: 60px !important;
    color: #000000;
    width: auto !important;
}
.home-banners .home-banner h3{padding-left: 20%;}
.home-rating-banner h6{padding-right: 0px;}
.welcome-stf h2 {
  font-size: 40px;
  line-height: 50px;
  font-weight: 500;
  padding: 98px 30px 0px;
}

.welcome-stf p {
  padding: 0px 100px 100px 30px;
}
.welcome-stf h4{padding-top: 50px;font-size: 30px;}
.welcome-stf .line1 {
  margin: 30px 30px 20px 20px;
}
.apart h3 {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: left;
  padding: 50px 50px 30px;
  width: 100%;
}.apart h3 {
    font-size: 40px;
    font-weight: 500;
    line-height: 50px;
}
.g-section {
  padding: 29px 3%;
}
.our-service h5{padding-top: 20px;}
.our-service .service-img{width:210px;}
.owl-carousel .owl-stage-outer{height: 350px;}
.client-word .service-img {
  margin-top: -360px;
  width: 175px;
}
}