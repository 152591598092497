.home-banner {
  background-color: #f1f5ef;
  padding: 3rem 0 0 0;
}

.home-banner h1 {
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 130%;
  color: #000000;
}

.home-banner .right-info {
  text-align: center;
  margin-bottom: 40px;
}

.home-banner .right-info img {
  width: 100%;
  margin: 10px 0;
}

.home-banner .banner-from {
  margin-bottom: 40px;
}

.banner-from .team-btn {
  border-radius: 5px;
  padding: 8px 20px;
  color: white !important;
  font-weight: 500;
  margin-top: 5px;
  font-size: 15px;
  border: none;
}

.home-banner .banner-from .card-header {
  background: #91d11b;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin: 0;
}

.home-banner .banner-from .card-header h5 {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 104.5%;
  text-align: center;
  color: #fff;
}

.home-banner .banner-from input,
.home-banner .banner-from select {
  background: #ffffff;
  border: 1px solid rgba(78, 86, 88, 0.5);
  border-radius: 5px;
  color: rgba(78, 86, 88, 0.8);
  min-height: 38px;
  font-size: 14px;
}

.home-banner .banner-from .col-lg-6,
.home-banner .banner-from .col-lg-12 {
  padding: 0 5px;
}

.new-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 55px;
  line-height: 82px;
  color: #000000;
}

.new-sub-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  color: #000000;
  margin-bottom: 1rem;
}

.who-are {
  margin: 2rem 0;
  text-align: center;
  padding: 3rem 0;
}

.who-are .business-card {
  margin-top: 20px;
  position: relative;
}

.who-are .business-card:after {
  content: "";
  position: absolute;
  background-color: #91d11b;
  width: 32px;
  height: 81px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  left: -20px;
  top: 25px;
}

.who-are .business-card .info {
  position: absolute;
  top: 50%;
  left: 46%;
  transform: translate(-50%, -50%);
}

.who-are .business-card .info h5 {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 90px;
  color: #000000;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.who-are .business-card .info h5 img {
  width: 90px;
}

.who-are p {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  color: #000000;
  margin-bottom: 1rem;
  text-align: justify;
  margin-top: 50px;
}

.who-are .business-card .main-img {
  width: 100%;
}

.heading {
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  color: #000000;
  width: fit-content;
  margin: 20px auto;
  padding-bottom: 5px;
  max-width: unset;
  line-height: 1.2;
}

.heading-border {
  border-bottom: 4px solid #91d11b;
}

.our-services {
  background-color: #f1f5ef;
  padding: 3rem 0;
}

.scale-business {
  background-color: #f1f5ef;
  padding: 3rem 0 0 0;
  text-align: center;
}

.section-disc {
  font-style: normal;
  font-weight: 300;
  font-size: 21px !important;
  line-height: 150%;
  text-align: center;
  margin-bottom: 40px;
  color: #000000;
}

.our-services ul {
  list-style: none;
  padding: 0;
}

.servies-list h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 45px;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 30px;
}

.servies-list h5 img {
  margin-right: 8px;
  height: 40px;
}

.servies-list li {
  margin-bottom: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 118.5%;
  letter-spacing: -0.02em;
  color: #000000;
  display: flex;
  align-items: flex-start;
}

.servies-list li span {
  line-height: 0;
  background-color: #91d11b;
  border-radius: 10px;
  padding: 1px 8px;
  margin-right: 6px;
  color: white;
  margin-top: 5px;
  font-size: 12px;
}

.our-services .servies-list.border-list {
  border-right: 2px solid #91d11b;
}

.advantages {
  padding: 3rem 0;
}

.global-employer {
  background-color: #f1f5ef;
  padding: 3rem 0;
}

.global-employer .info-box {
  text-align: center;
  margin-top: 30px;
}

.global-employer .info-box h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 150%;
  text-align: center;
  color: #000000;
  margin-top: 10px;
}

.strategic-partners {
  padding: 3rem 0;
}

.strategic-partners img {
  margin-top: 4rem;
}

.how-can-help {
  background-color: #f1f5ef;
  padding: 3rem 0;
}

.how-can-help h5 {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
}

.how-can-help h5 img {
  width: 29px;
  margin-right: 7px;
}

.how-can-help p {
  font-size: 16x;
}

.how-can-help .col-container {
  margin-top: 1rem;
}

.built-point {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 115%;
  color: #000000;
  position: relative;
  padding-left: 20px;
  text-align: left;
  margin-top: 10px;
  font-weight: 600;
  margin-bottom: 1rem;
}

.built-point::after {
  position: absolute;
  content: "";
  left: 0;
  width: 12px;
  height: 12px;
  background-color: #91d11b;
  border-radius: 100%;
  top: 4px;
}

.how-work {
  background-color: #f1f5ef;
  padding: 3rem 0;
}

.how-work .step-work-box {
  position: relative;
}

.how-work .col-lg-3 {
  padding: 0;
}

.how-work .step-work-box .main-img {
  width: 100%;
}

.how-work .step-work-box-sm {
  margin-top: 48px;
}

.how-work .work-info {
  border: 20px solid #91d11b;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 213px;
  width: 213px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  border-radius: 100%;
  top: -150px;
  background-color: #f1f5ef;
  text-align: center;
  padding-top: 20px;
}

.how-work .work-info img {
  max-width: 80px;
  margin: 10px 0;
}

.how-work .work-info h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #000000;
}

.how-work .step-work-box p {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 150%;
  text-align: justify;
  letter-spacing: 0.02em;
  margin-right: 30px;
  color: #000000;
  margin-top: 10px;
}

.how-work-2 {
  padding: 3rem 0;
}

.work-card {
  border-radius: 10px;
  background: #f1f5ef;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: none;
}

.work-card h5 {
  font-weight: 700;
  font-size: 26px;
  margin: 10px 0;
}

.work-card h6 {
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
}

.working-steps {
  margin-top: 180px;
}

.effectivenes-employees {
  padding: 3rem 0;
}

.effectivenes-employees .contant-box {
  position: relative;
}

.effectivenes-employees .main-img {
  height: 350px;
  width: 100%;
}

.effectivenes-employees .text-box {
  background: #91d11b;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
  width: 100%;
  padding: 10px;
  top: 100px;
  left: -42px;
  padding: 20px;
}

.hire-talent {
  background-color: #f1f5ef;
  padding: 3rem 0;
}

.hire-talent .banner-from h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 130%;
  text-align: center;
  color: #000000;
  margin-bottom: 20px;
}

.client-word {
  padding: 3rem 0;
}

.client-word .card {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.client-word .card img {
  max-width: 250px;
  margin: auto;
  margin-bottom: 1rem;
}

.client-word .card h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 120.5%;
  text-align: center;
  color: #000000;
}

.client-word .card p {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 120.5%;
  text-align: center;
  color: #000000;
}

#customers-testimonials .item {
  text-align: center;
  padding: 0px;
  margin-bottom: 40px;
  -webkit-transform: scale3d(0.8, 0.8, 1);
  transform: scale3d(0.8, 0.8, 1);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#customers-testimonials .owl-item.active.center .item {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

#customers-testimonials.owl-carousel .owl-dots .owl-dot.active span,
#customers-testimonials.owl-carousel .owl-dots .owl-dot:hover span {
  background: #d9d9d9;
  transform: translate3d(0px, -50%, 0px) scale(0.9);
}

#customers-testimonials.owl-carousel .owl-dots {
  display: inline-block;
  width: 100%;
  text-align: center;
}

#customers-testimonials.owl-carousel .owl-dots .owl-dot {
  display: inline-block;
}

#customers-testimonials.owl-carousel .owl-dots .owl-dot span {
  background: #d9d9d9;
  display: inline-block;
  height: 15px;
  margin: 0 2px 5px;
  transform: translate3d(0px, -50%, 0px) scale(0.6);
  transform-origin: 50% 50% 0;
  transition: all 250ms ease-out 0s;
  width: 15px;
  border-radius: 100%;
}

.main-catagiory h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 115%;
  color: #000000;
  position: relative;
  padding-left: 20px;
  text-align: left;
  margin-top: 10px;
}

.main-catagiory h5::after {
  position: absolute;
  content: "";
  left: 0;
  width: 12px;
  height: 12px;
  background-color: #91d11b;
  border-radius: 100%;
  top: 4px;
}

.home-rating-banner img {
  width: 100px !important;
  margin: 0 !important;
}

.home-rating-banner h6 {
  text-align: left;
  font-size: 13px;
}

.home-rating-banner h6 span {
  margin-top: 5px;
}

.lndng-auto {
  text-align: center !important;
  margin: 20px 110px !important;
}

.landing-hire-block {
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.10);
  margin: 30px 30px 0px 0px;
}

.landing-hire-block span {
  width: 25px;
  height: 25px;
  display: block;
  background: #91D11B;
  float: left;
  border-radius: 15px;
}

.landing-hire-block h4 {
  font-size: 17px;
  font-weight: 600;
  float: left;
  padding: 3px 15px;
}

.landing-hire-block p {
  font-size: 16px;
  text-align: left;
}

.landing-hire-bar {
  padding-right: 0px
}

.landing-hire-bar .bar1 {
  width: 36%;
  height: 55px;
  background: #91D11B;
  border-radius: 20px;
  font-size: 20px;
  padding: 12px;
  font-weight: 600;
  margin: 20px 0px 0px 0px;
  float: left;
}

.landing-hire-bar .bar3 {
  width: 36%;
  height: 55px;
  background: #D8F5A3;
  font-size: 20px;
  padding: 12px;
  font-weight: 600;
  border-radius: 20px;
  float: left;
  margin: 20px 0px 0px -4%;
}

.landing-hire-bar .bar2 {
  width: 36%;
  height: 55px;
  font-size: 20px;
  padding: 12px;
  font-weight: 600;
  background: #B7E168;
  border-radius: 20px;
  float: left;
  margin: 20px 0px 0px -4%;
}

.lndg-banner h1,
.lndg-banner h3 {
  text-align: center;
  width:100%;
}

.lndg-banner h3 {
  margin-bottom: 0px !important;
}

.lndg-banner .align-items-end {
  margin-top: 50px;
  align-items: flex-start !important;
}

.lndg-banner .banner-from {
  margin-bottom: -60px;
  margin-top: 50px;
}

.lndg-banner .banner-from .card .card-body {
  margin-top: -10px;
  background: #FFF;
  border-radius: 10px;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.15);
}

.lndg-banner .home-rating-banner {
  margin-top: 150px !important;
}

.lndg-banner .main-catagiory h5 {
  font-size: 22px;
}

.benefits {
  padding-bottom: 100px;
}

.benefits .heading {
  max-width: 750px;
}

.greenbox {
  width: 45px;
  background: #91D11B;
  height: 45px;
  border-radius: 15px 5px 5px 5px;
  margin-right: 15px;
}

.greenbox img {
  display: block;
  margin: auto;
  margin-top: 12px;
}

.hire-number span {
  background: #F1F5EF;
  width: 45px;
  height: 45px;
  font-size: 30px;
  color: #91D11B;
  text-align: center;
  display: block;
  float: left;
  border-radius: 23px;
  box-shadow: 0px 3px #ccc;
}

.hire-number {
  padding: 10px 30px 20px;
}

.hire-number img {
  margin-top: 23px;
}

.hire-number h3 {
  clear: both;
  font-size: 22px;
  font-weight: 600;
  padding: 15px 0px 0px;
}

.hire-border {
  z-index: 9;
  border-radius: 30px;
  margin-top: -100px;
  background: #fff;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.10);
}

@media (max-width: 767px) {
  .who-are .business-card:after {
    display: none;
  }
}

@media (max-width: 767px) {
  .who-are .business-card .main-img {
    width: 100%;
  }

  .who-are .business-card:after {
    display: none;
  }

  .our-services .servies-list.border-list {
    border: none;
  }

  .strategic-partners img {
    margin-top: 2rem;
  }
}

@media (max-width: 998px) {
  .how-work .step-work-box {
    margin-top: 210px;
  }

  .working-steps {
    margin-top: 0px;
  }

  .how-work .step-work-box p {
    margin-right: 0;
  }

  .how-work .col-lg-3 {
    padding: 0 15px;
  }

  .effectivenes-employees .text-box {
    position: unset;
    margin-top: 10px;
    border-radius: 10px;
  }

  .hire-talent .banner-from {
    margin-top: 30px;
  }

  .hire-talent {
    border-top-left-radius: 50px;
  }

  .who-are .business-card .info h5 {
    font-size: 25px;
  }

  .advantages .col-lg-3 {
    text-align: center;
  }

  .advantages .col-lg-3 img {
    margin-top: 1rem;
  }

  .servies-list ul {
    padding-left: 10px;
  }
}