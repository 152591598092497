.home-banner.innerHome-banner .heading-lg {
  font-size: 40px;
  text-align: left;
  line-height: 1;
  margin: 0 0 20px 0;
  max-width: 100%;
}
.home-banner.innerHome-banner .sub-info {
  font-weight: 500;
  font-size: 29px;
  line-height: 118.5%;
  letter-spacing: -0.02em;
  text-align: left;
  max-width: 400px;
}
.services-offer {
  padding: 3rem 0;
}
.services-offer .sub-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 118.5%;
  letter-spacing: -0.02em;
  color: #000000;
}
.services-offer ul {
  padding-left: 18px;
  margin-top: 1rem;
}
.services-offer ul li {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 107%;
  color: #000000;
  margin-bottom: 15px;
}
.services-offer .info-area h5 {
  font-weight: 500;
  font-size: 18px;
  line-height: 118.5%;
  letter-spacing: -0.02em;
  color: #000000;
}
.services-offer .info-area li {
  list-style: auto;
}
.industries.skilled-professional {
  background-color: #f1f5ef;
}
.img-50{
  max-width: 400px;
}
.my-more li{
  margin-bottom: 2rem!important;
  font-size: 16px!important;
}

@media (max-width: 998px) {
  .home-banner.innerHome-banner .heading-lg {
    font-size: 40px;
  }
  .home-banner.innerHome-banner .sub-info {
    font-size: 18px;
  }
  .services-offer .info-area {
    margin-top: 20px;
  }
  .my-more li{
    margin-bottom: 1rem!important;
  }
}
