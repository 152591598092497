footer {
  background: #000000;
  position: relative;
  padding: 5rem 0 4rem 0;
}
footer .contact-info a{
  display: block;
  margin: -50px auto 15px 30px;
}
footer h6 {
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
}
footer h6 span {
  font-weight: normal;
}
footer p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}
footer h7 {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  display: block;
}
footer h7 span{font-weight: normal;}
footer h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  position: relative;
  color: #ffffff;
  margin-bottom: 1.5rem;
}
footer h5:after {
  content: "";
  position: absolute;
  width: 38px;
  height: 3px;
  background: #91d11b;
  bottom: -9px;
  left: 0;
}

footer .footer-logo {
  width: 328px;
  background: #ffffff;
  border-radius: 0px 0px 91.5px 10px;
  padding: 10px;
  top: 0;
  position: absolute;
}
footer .footer-logo img{width:95%;}
footer .pages-link {
  list-style: none;
  padding: 0;
}
footer .pages-link a {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #ffffff;
}
footer .pages-link li {
  margin-bottom: 0.5rem;
}
footer .input-group .form-control {
  height: 35px;
  border-radius: 7px 0px 0px 7px;
}
footer .input-group .btn-brnad {
  height: 35px;
  border: none;
  padding: 5px 15px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  background: #91d11b;
  border-radius: 0px 7px 7px 0px;
}

footer .social-link {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
}
footer .social-link li {
  margin-right: 10px;
}
footer .about{
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 15px;
  color: #ffffff;
}
.ftimg{margin-top: -70px;}
.ftimg img{    width: 120px;
  margin-right: 10px;}
