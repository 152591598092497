.protected-data {
  padding: 1rem 0;
}
.protected-data .heading {
  font-size: 40px;
  width: 100%;
  text-align: left;
}
.works-steps {
  padding: 3rem 0;
}
.works-steps .card {
  background: #f1f5ef;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: none;
  margin: 1rem 0;
  box-shadow: 16px 18px #91d11b;
  position: relative;
}

.works-steps .card h1 {
  font-weight: 600;
}
.works-steps .card .icon-img {
  margin-bottom: 10px;
}
.works-steps .snd-card {
  padding-top: 8rem;
  margin-bottom: 5rem;
  position: relative;
}
.works-steps .connecting-line1 {
  position: absolute;
  width: 140%;
  right: -140%;
  transform: rotate(-8deg);
  top: 50px;
}
.works-steps .connecting-line2 {
  position: absolute;
  width: 139%;
  left: 70%;
  transform: rotate(-5deg);
  top: -11rem;
  z-index: -1;
}

@media (max-width: 998px) {
  .works-steps .connecting-line1,
  .works-steps .connecting-line2 {
    display: none;
  }
  .works-steps .snd-card {
    padding-top: 0rem;
    margin-bottom: 0rem;
  }
  .works-steps .card {
    margin-bottom: 4rem;
  }
}


button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.on {
  color: #91d11b ;
}
.off {
  color: #ccc;
}
.rating{width:50%;float:left;}
.star-rating{float:right;margin-top:-10px;}
.star-rating .star{font-size:34px;}
.err-text{
    width: 100%;
    display: block;
    color: red;
}
.suc-text{
 width: 100%;
    display: block;
    color: green;
}