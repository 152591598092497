.innerHome-banner .new-banner-from .team-btn {
  border-radius: 5px;
  padding: 8px 20px;
  color: white;
  font-weight: 500;
  margin-top: 5px;
  font-size: 15px;
  border: none;
}
.home-banner.innerHome-banner .right-info {
  margin-bottom: 0;
}
.innerHome-banner .new-banner-from {
  margin-bottom: 0px;
}
.innerHome-banner .job-media img {
  margin-top: 20px;
}
.home-banner.innerHome-banner {
  padding: 3rem 0px 0 0;
}
.home-banner.innerHome-banner .right-info img {
  margin-top: 3rem;
  margin-bottom: 0;
}
.home-banner.innerHome-banner h1 {
  max-width: 476px;
  margin: auto;
}
.btn-brnd-outline {
  background-color: white;
  border: 4px solid #91d11b;
  color: black;
  font-weight: 600;
  border-radius: 30px;
  padding: 8px 30px;
  line-height: 2;
}
.btn-brnd-outline i {
  color: #91d11b;
}

.expertise .built-point {
  padding-left: 30px;
}
.expertise .built-point::after {
  left: 0px;
  width: 20px;
  height: 20px;
  top: 0px;
}

.why-use2 {
  padding: 3rem 0;
}
.why-use2 .heading {
  margin: 20px 0;
}
.why-use2 img {
  border-radius: 10px;
}
.our-services.expertise .servies-list li {
  font-weight: 400;
  font-size: 19px;
  line-height: 140%;
}
.our-services.expertise .servies-list li span {
  margin-right: 20px;
}
.problem-challenges .chalange-box {
  text-align: center;
  max-width: 210px;
  margin: auto;
  margin-top: 20px;
}
.problem-challenges .chalange-box img {
  width: 110px;
}
.problem-challenges .chalange-box p {
  font-weight: 400;
  font-size: 18px;
  line-height: 118.5%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;
  margin-top: 15px;
}
.problem-challenges .card {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  margin-top: 40px;
}
.problem-challenges .card .info-box {
  padding: 30px 50px;
}
.problem-challenges .card .info-box .new-heading {
  font-size: 40px;
  max-width: 328px;
  line-height: normal;
  margin-bottom: 10px;
}
.problem-challenges .card .info-box h6 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 118.5%;
  letter-spacing: -0.02em;
  margin-top: 20px;
}
.col-container {
  display: flex;
  flex-direction: column;
}
.the-solution h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 44px;
  text-align: center;
  color: #000000;
  text-align: start;
  margin-bottom: 20px;
}

.the-solution p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #000000;
}

.the-solution .card {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  margin-top: 50px;
}
.the-solution .card .card-body {
  max-width: 784px;
  margin: auto;
}

.the-solution .card h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 44px;
  text-align: center;
  color: #000000;
}
.the-solution .card p {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 44px;
  text-align: center;
  color: #000000;
}
.the-solution .card .info-box {
  display: flex;
  align-items: center;
  max-width: 350px;
  margin-top: 30px;
}

.the-solution .card .info-box h6 {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  color: #000000;
  text-align: start;
}
.employee-performance {
  background-color: #f1f5ef;
  padding: 3rem 0;
  text-align: center;
}
.employee-performance .employee-box {
  margin-top: 3rem;
  text-align: center;
}
.employee-performance .employee-box img {
  height: 100px;
}
.employee-performance .employee-box p {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  text-align: center;
  color: #000000;
  margin-top: 20px;
}
.maintaining .new-heading {
  text-align: center;
}
.maintaining {
  background-color: white;
  padding: 3rem 0;
}
.maintaining p {
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 130%;
  text-align: start;
  color: #000000;
}
.available-clock {
  padding: 3rem 0;
}
.available-clock .heading {
  margin-bottom: 3rem;
}

.available-clock .built-point {
  font-weight: normal;
  margin-top: 2rem;
  padding-left: 30px;
}

.available-clock .built-point::after {
  width: 15px;
  height: 15px;
}

.maintaining-confidentiality {
  padding: 3rem 0;
}
.maintaining-confidentiality .icon-img {
  height: 80px;
  margin-bottom: 1.5rem;
}
@media (max-width: 998px) {
  .home-banner.innerHome-banner h1 {
    font-size: 30px;
  }
  .heading {
    font-size: 26px;
  }
  .our-services.expertise .servies-list li,
  .the-solution p {
    font-size: 16px;
  }
  .new-heading {
    font-size: 26px;
    line-height: normal;
  }
  .new-sub-heading {
    font-size: 26px;
    line-height: 40px;
  }
  .problem-challenges .chalange-box {
    margin-top: 40px;
  }
  .problem-challenges .card .info-box .new-heading,
  .the-solution h4,
  .the-solution .card h2 {
    font-size: 25px;
  }
  .the-solution .card h2 {
    line-height: normal;
  }
  .problem-challenges .card .info-box h6 {
    font-size: 20px;
  }
  .the-solution .card .info-box h6 {
    font-size: 20px;
    line-height: 119%;
  }
}
