.faq-contaner {
  margin: auto;
  width: 55%;
  padding: 10px;
}

@media (max-width: 767px) {
  .faq-contaner {
    width: 100%;
    padding: 20px;
  }
}

.faq-data,
.faq-heading {
  text-align: left;
  border: 1px solid #e0e0e0;
}

.faq-heading {
  padding: 25px 40px;
  margin-top: 35px;
}

.faq-heading p {
  font-size: 18px;
  display: inline;
}

.faq-heading a {
  text-align: center;
  font-size: 24px;
  margin-top: 5px;
  line-height: 23px;
  border: 1px solid #e2e2e2;
  width: 26px;
  height: 25px;
  float: right;
  color: #000;
  background: #91d11b;
}

.faq-heading a:hover {
  color: #fff !important;
  background: #91d11b;
}

.faq-data {
  position: relative;
  padding: 21px 40px;
  z-index: 1;
  border-top: 0;
  border-radius: 0 0 0 0;
}

.faq-data p,
.faq-data ul {
  font-size: 15px;
  color: #000;
}
