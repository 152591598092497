.decided-provided {
  padding: 3rem 0;
}

.decided-provided .card {
  margin-top: 1rem;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  height: 100%;
}
.decided-provided .card {
  margin-top: 2rem;
}
.decided-provided .card p {
  text-align: start;
  font-size: 12px;
  padding-left: 12px;
  margin: 0.5rem;
  color: #000000;
}
.decided-provided .card a {
  text-decoration: none;
}

.decided-provided .card .main-heading {
  position: relative;
  padding-left: 2rem;
  margin-bottom: 1rem;
  width: fit-content;
  font-weight: 700;
  font-size: 20px;
  min-height: 52px;
  line-height: 118.5%;
  letter-spacing: -0.02em;
  color: #000000;
  text-align: left;
}
.decided-provided .card .main-heading:after {
  position: absolute;
  content: "";
  background: #91d11b;
  border-radius: 100%;
  width: 18px;
  height: 18px;
  left: 0;
  top: 3px;
}
.how-help {
  padding: 1rem 0;
}
.how-help .heading {
  margin-bottom: 2rem;
}

.how-help p {
  margin: 1rem 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 138.25%;
  text-align: justify;
  letter-spacing: 0.015em;
  color: #000000;
}

@media (max-width: 998px) {
}
