
.contact-us-img {
  padding-top: 24px;
  text-align: center;
}

.contact-us-form {
  padding: 40px 0 10px;
  font-size: 17px;
  color: #252525;
}

.contact-us-form h1 {
  display: block;
  font-size: 35px;
  line-height: 35px;
  padding-bottom: 15px;
}

.contact-us-form select::-ms-expand {
  display: none;
}

.form-text-box {
  width: 100%;
  height: 55px;
  background: #f6f7f8;
  padding: 0 25px;
  border: 0;
  outline: 0;
  border-radius: 6px;
  font-weight: 300;
  margin-top: 20px;
  font-size: 16px;
  line-height: 16px;
  color: #868686;
}

.form-text-box::-webkit-input-placeholder {
  color: #868686;
}

.form-text-box:-ms-input-placeholder {
  color: #868686;
}

.form-text-box::-ms-input-placeholder {
  color: #868686;
}

.form-text-box::placeholder {
  color: #868686;
}

select.form-text-box {
  
  padding-right: 45px;
}

.chat-box a,
.submit-btn {
  text-align: center;
  background: #91d11b;
  color: #000;
  border-radius: 30px;
  font-weight: 700;
  text-transform: uppercase;
}

.submit-btn {
  width: 100%;
  height: 55px;
  padding: 10px;
  margin-top: 20px;
}

.submit-btn:hover {
  color: #fff;
  background: #91d11b;
}

.contact-us-call-content {
  background: #fff;
  padding: 38px 15px 40px;
  margin-bottom: 65px;
  border-radius: 15px;
}

.contact-us-call-box {
  padding: 0 70px 20px;
  font-size: 17px;
  color: #252525;
}

.contact-us-call-box h1 {
  display: block;
  font-size: 35px;
  line-height: 35px;
  padding-bottom: 25px;
}

.contact-us-call-box.rl-border {
  border-right: 1px solid #dbdbdb;
  border-left: 1px solid #dbdbdb;
  margin-right: -15px;
  margin-left: -15px;
}

.contact-us-call-box.chat-box {
  padding-right: 90px;
  padding-bottom: 0;
}

.contact-us-call-box a {
  display: block;
  padding: 13px;
  font-size: 13px;
  line-height: 13px;
  margin-top: 15px;
}
.contact-us-call-box a:hover {
  color: #000;
  text-decoration: underline;
}

.chat-box a:hover,
.get-cal-btn:hover,
.public-face-content a {
  color: #fff !important;
  background: #91d11b;
}
.recaptcha{margin-top:20px;}