.industries {
  padding: 3rem 0;
}

.industries .info-box {
  padding: 2rem 0;
  text-align: center;
  max-width: 150px;
  margin: auto;
}

.industries .info-box img {
  height: 80px;
}

.industries .info-box p {
  font-weight: 500;
  font-size: 20px;
  line-height: 118.5%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;
  margin-top: 20px;
}

.build-team.business-need {
  background-color: #f1f5ef;
  padding: 3rem 0;
}

.build-team.business-need .buildteam-inner-sec {
  margin-top: 1rem;
}

.build-team.business-need .sub-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 120%;
  color: #000000;
}

.build-team.business-need h6 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
}

.build-team.business-need .servies-list li {
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  text-align: justify;
  color: #000000;
}

.cal-center {
  padding: 5rem 0;
}

.cal-center .box-head {
  padding: 10px 20px;
  text-align: center;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  margin-bottom: 30px;
  margin-left: 20px;
}

.cal-center .box-head:after {
  content: "";
  position: absolute;
  background: #91d11b;
  border-radius: 10px 0px 0px 10px;
  width: 20px;
  height: 50px;
  left: -20px;
  top: 7px;
}

.cal-center .box-head h5 {
  font-weight: 700;
  font-size: 30px;
  line-height: 120%;
  color: #000000;
}

.cal-center .icon-img {
  margin-top: 20px;
  margin-bottom: 10px;
  width: 66px;
  max-height: 66px;
}

.cal-center p {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: justify;
  margin-top: 20px;
}

.our-client {
  padding: 3rem 0;
}

.our-client h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 120.5%;
  color: #000000;
  margin-top: 10px;
}

.our-client p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: justify;
  color: #000000;
}

.our-client {
  padding: 3rem 0;
}

@media (max-width: 998px) {
  .build-team.business-need .sub-heading {
    font-size: 25px;
    line-height: normal;
  }
}
