header .navbar-brand img {
  max-width: 210px;
}
header .nav-link {
  text-transform: uppercase;
  margin-right: 7px;
  color: #4d5557;
  font-weight: 700;
  font-size: 13px;
}
.nav-link{padding: 1rem;}
header .nav-link.active {
  color: #A0CD4E;
}
header .navbar .dropdown-menu {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  border: none;
}

header .back-call-btn {
  text-align: center;
  padding: 11px 10px 13px;
  font-size: 14px !important;
  background: #91d11b;
  color: #fff;
  text-transform: uppercase;
  min-width: 155px;
  line-height: 16px;
  border-radius: 40px;
  border: none;
}
.dropdown1:hover .dropdown-submenu{display:block;left:199px;top:0;}
.dropdown2:hover .dropdown-submenu2{display:block;left:199px;top:0;}
.dropdown1 .dropdown-toggle::after, .dropdown2 .dropdown-toggle::after
{display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.55em;
    vertical-align: 0.05em;
    content: "";
    border-top: 0.35em solid transparent;
    border-right: 0;
    border-bottom: 0.35em solid transparent;
    border-left: 0.35em solid;
    color: #91d11b;
	}
	.mt15{margin-top:-15px;}
  .show-on-mobile{display: none;}
  @media (max-width: 768px) {
    .dropdown-submenu, .dropdown-submenu2 {
      
      display: block;
  }
  .show-on-mobile{display: block;}
  .dark-head {
    position: fixed;
    top: 0px;
    background: #fff;
    width: 100%;
    z-index: 9;
    left: 0px;
  }
}
